import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { useGetPersonalizedPlaylistQuery } from "services/graphql";
import { Section } from "../Section";

export function PersonalizedPlaylist() {
  const { data, loading, error } = useGetPersonalizedPlaylistQuery();

  if (loading) {
    return <></>;
  }

  if (error) {
    return <></>;
  }

  if (!data) {
    return <></>;
  }

  const { myPersonalizedPlaylist } = data;

  if (!myPersonalizedPlaylist) {
    return <></>;
  }

  const { personalizedPlaylist } = myPersonalizedPlaylist;

  if (!personalizedPlaylist) {
    return <></>;
  }

  return (
    <Section
      title="Your Weekly Recommendations"
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.playlists__weekly_recommendations}`,
        text: "See All",
      }}
    >
      <Slider
        data={personalizedPlaylist}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.RecommendedWeeklyPlaylist}
            selectedFrom="WeeklyRecommendations"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
}
